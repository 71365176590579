<script lang="ts">
	import { onMount } from 'svelte';
	import { page } from '$app/stores';
	import hot from '$lib/images/hot.svg';
	import { height } from '$lib/store.js';
	import { ElectrumClient, ElectrumTransport } from '@electrum-cash/network';
	import { IndexedDBProvider } from '@mainnet-cash/indexeddb-storage';
	import { FutureWallet } from '@fbch/lib';
	import { BaseWallet } from 'mainnet-js';

	let heightValue: number;
	let walletError = false;
	let wallet;
	let walletState: string;
	let balance;

	height.subscribe((value: any) => {
		console.log(heightValue);
		heightValue = value;
	});

	function updateHeight(newHeight: any) {
		console.log(heightValue);
		height.set(newHeight);
	}

	// Set up a callback function to handle new blocks.
	const handleNotifications = function (data: any) {
		if (data.method === 'blockchain.headers.subscribe') {
			let d = data.params[0];
			if (d.height && d.height > 1) updateHeight(d.height);
		} else if (data.method === 'blockchain.address.subscribe') {
			if (data.params[0] == wallet.getTokenDepositAddress()) {
				if (data.params[1] !== walletState) {
					walletState = data.params[1];
					updateWallet();
				}
			}
		}
	};
	async function updateWallet() {
		balance = await wallet.getBalance('bch');
	}

	onMount(async () => {
		try {
			BaseWallet.StorageProvider = IndexedDBProvider;
			wallet = await FutureWallet.named('hot');
			balance = await wallet.getBalance('bch');
		} catch (e) {
			walletError = true;
			throw e;
		}

		// Initialize an electrum client.
		const electrum = new ElectrumClient(
			'FBCH',
			'1.4.1',
			'bch.imaginary.cash',
			ElectrumTransport.WSS.Port,
			ElectrumTransport.WSS.Scheme
		);

		// Wait for the client to connect
		await electrum.connect().then(() => {
			// Listen for notifications.
			electrum.on('notification', handleNotifications);
			electrum.subscribe('blockchain.headers.subscribe');
			electrum.subscribe('blockchain.address.subscribe', wallet.getTokenDepositAddress());
		});
	});
</script>

<div class="warn">
	Alpha release. Use at your own risk.<br />
	Bugs and usability issues may result in loss of funds.
</div>

<div class="wallet">
	{#if wallet}
		{#if walletError}
			⚠️
		{/if}
		{#if balance == 0}
			Deposit coins to claim coupons ☞
		{/if}

		<a href="/wallet">
			<img width="30" src={hot} alt="wallet" />

			{#if typeof balance !== 'undefined'}
				{balance} BCH
			{/if}
		</a>
	{/if}
</div>

<header>
	<div class="corner">
		<a href="/">
			<img src="/FBCH.svg" alt="Home" />
		</a>
	</div>
	<nav>
		<svg viewBox="0 0 2 3" aria-hidden="true">
			<path d="M0,0 L1,2 C1.5,3 1.5,3 2,3 L2,0 Z" />
		</svg>
		<ul>
			<li style="font-weight: 900;" aria-current={$page.url.pathname === '/' ? 'page' : undefined}>
				<a style="font-size: larger" href="/"><b>&#9432</b></a>
			</li>
			<li aria-current={$page.url.pathname === '/vaults' ? 'page' : undefined}>
				<a href="/vaults">Vaults</a>
			</li>
			<li aria-current={$page.url.pathname === '/write' ? 'page' : undefined}>
				<a href="/write">Write</a>
			</li>
		</ul>
		<svg viewBox="0 0 2 3" aria-hidden="true">
			<path d="M0,0 L0,3 C0.5,3 0.5,3 1,2 L2,0 Z" />
		</svg>
	</nav>
	<div class="status">
		{#if Number(heightValue) > 0}
			<div>
				{heightValue.toLocaleString()}<sub>■</sub>
			</div>
			<div>
				T -{(1000 - (heightValue % 1000)).toLocaleString()}<sub>■</sub>
			</div>
		{/if}
	</div>
</header>

<style>
	header {
		display: flex;
		justify-content: space-between;
	}
	.wallet {
		position: sticky;
		top: 0px;
		display: flex;
		align-items: center;
		justify-content: right;
		height: 32px;
		padding: 0.5em;
		background-color: #ffffff88;
	}

	.wallet a {
		color: black;
		display: flex;
	}
	.status {
		display: grid;
		text-align: right;
		font-weight: 900;
		font-size: small;
		margin: auto;
		color: rgba(255, 255, 255, 0.7);
	}

	.corner {
		width: 3em;
		height: 3em;
	}

	.corner a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	.corner img {
		width: 2em;
		height: 2em;
		object-fit: contain;
	}

	nav {
		display: flex;
		margin: auto;
		justify-content: center;
		--background: rgba(255, 255, 255, 0.7);
	}

	svg {
		width: 2em;
		height: 3em;
		display: block;
	}

	path {
		fill: var(--background);
	}

	ul {
		position: relative;
		padding: 0;
		margin: 0;
		height: 3em;
		display: flex;
		justify-content: center;
		align-items: center;
		list-style: none;
		background: var(--background);
		background-size: contain;
	}

	li {
		position: relative;
		height: 100%;
	}

	li[aria-current='page']::before {
		--size: 15px;
		content: '';
		width: 0;
		height: 0;
		position: absolute;
		top: 0;
		left: calc(15% - var(--size));
		border: var(--size) solid transparent;
		border-top: var(--size) solid var(--color-theme-1);
	}

	nav a {
		display: flex;
		height: 100%;
		align-items: center;
		padding: 0 1rem 0 0;
		color: var(--color-text);
		font-weight: 700;
		font-size: 0.8rem;
		text-transform: uppercase;
		text-decoration: none;
		transition: color 0.2s linear;
	}

	a:hover {
		color: var(--color-theme-1);
	}

	.warn {
		font-weight: 900;
		font-size: smaller;
		padding: 0.2em;
		background-color: #ffe13e;
	}
</style>
